<template>
  <div class="main">
    <img style="width: 100%" src="../../../assets/images/quote.jpg">
    <tabs v-model="activeName">
      <tab-pane :label="$t('Personal customization')" name="personal"></tab-pane>
      <tab-pane :label="$t('Group Company customization')" name="group"></tab-pane>
    </tabs>
    <div class="fl-group">
      <div class="bd">
        <!--公司/機構名稱-->
        <div class="form-item" v-if="activeName==='group'">
          <div class="label" :class="{'is-error': $v.companyName.$error}">{{$t('Company Institution Name')}}</div>
          <div>
            <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
                   :class="{'is-error': $v.companyName.$error}"
                   @blur="$v.companyName.$touch()"
                   v-model.trim="companyName"/>
          </div>
        </div>
        <!--活動名稱(選填)-->
        <div class="form-item">
          <div class="label">{{$t('Event name')}}({{$t('Optional')}})</div>
          <div>
            <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model.trim="title"/>
          </div>
        </div>
        <div class="cl">
          <!--出發地-->
          <div class="fl form-item m-r">
            <div class="label" :class="{'is-error': $v.departure.$error}">{{$t('Departure')}}</div>
            <div>
              <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
                     :class="{'is-error': $v.departure.$error}"
                     @blur="$v.departure.$touch()"
                     v-model.trim="departure"/>
            </div>
          </div>
          <!--目的地-->
          <div class="fl form-item">
            <div class="label" :class="{'is-error': $v.destination.$error}">{{$t('Destination')}}</div>
            <div>
              <input class="ipt" type="text" autocomplete="off"
                     :placeholder="$t('Multiple destinations can be entered')"
                     :class="{'is-error': $v.destination.$error}"
                     @blur="$v.destination.$touch()"
                     v-model.trim="destination"/>
            </div>
          </div>
        </div>
        <div class="cl">
          <!--預計出發日期-->
          <div class="fl form-item m-r">
            <div class="label" :class="{'is-error': $v.departureDate.$error}">{{$t('Expected departure date')}}</div>
            <div>
              <el-date-picker class="select"
                              type="date"
                              size="small"
                              :picker-options="pickerOptions"
                              value-format="yyyy-MM-dd"
                              :placeholder="$t('PleaseChoose')"
                              :class="{'is-error': $v.departureDate.$error}"
                              @blur="$v.departureDate.$touch()"
                              v-model="departureDate">
              </el-date-picker>
            </div>
          </div>
          <!--期望出行天數-->
          <div class="fl form-item">
            <div class="label" :class="{'is-error': $v.traveledDay.$error}">{{$t('Expected journey days')}}</div>
            <div>
              <el-input-number class="input-number txt-l" :min="1" :max="365" size="small"
                               :placeholder="$t('PleaseEnter')"
                               :controls="false"
                               step-strictly
                               :class="{'is-error': $v.traveledDay.$error}"
                               @blur="$v.traveledDay.$touch()"
                               v-model.trim="traveledDay"/>
            </div>
          </div>
        </div>
        <!--預計參加人數-->
        <div class="form-item">
          <div class="label" :class="{'is-error': $v.adultJoinPeople.$error || $v.childJoinPeople.$error}">
            {{$t('Expected number of participants')}}
          </div>
          <div class="cl">
            <div class="fl m-r" style="min-width:220px">
              <el-input-number class="input-number w1" :min="0" :max="99999" size="small" step-strictly
                               :class="{'is-error': $v.adultJoinPeople.$error}"
                               @blur="$v.adultJoinPeople.$touch()"
                               v-model.trim="adultJoinPeople"/>
              <span class="vam"> {{$t('Adult')}}</span>
            </div>
            <div class="fl">
              <el-input-number class="input-number w1" :min="0" :max="99999" size="small" step-strictly
                               :class="{'is-error': $v.childJoinPeople.$error}"
                               @blur="$v.childJoinPeople.$touch()"
                               v-model.trim="childJoinPeople"/>
              <span class="vam"> {{$t('Child')}}(2-11{{$t('YearOld')}})</span>
            </div>
          </div>
        </div>
        <!--預算貨幣-->
        <div class="form-item">
          <div class="label" :class="{'is-error': $v.billingType.$error}">{{$t('BudgetCurrency')}}</div>
          <div>
            <el-select class="select" size="small" :placeholder="$t('PleaseChoose')"
                       :class="{'is-error': $v.billingType.$error}"
                       @blur="$v.billingType.$touch()"
                       v-model="billingType">
              <el-option v-for="item in currencyOptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.code">
              </el-option>
            </el-select>
          </div>
        </div>
        <!--預算類型-->
        <div class="form-item">
          <div class="label" :class="{'is-error': $v.budgetType.$error}">{{$t('Budget type')}}</div>
          <div>
            <el-select class="select" size="small" :placeholder="$t('PleaseChoose')"
                       :class="{'is-error': $v.budgetType.$error}"
                       @blur="$v.budgetType.$touch()"
                       v-model="budgetType">
              <el-option v-for="item in budgetTypeOptions"
                         :key="item.code"
                         :label="item.name"
                         :value="item.code">
              </el-option>
            </el-select>
          </div>
        </div>
        <!--预计预算-->
        <div class="form-item">
          <div class="label" :class="{'is-error': $v.expectedPrice.$error}">
            {{$t('Budget')}} <span style="color:#666;font-size:12px;">{{$t('Please enter Number between 0-999999999')}}</span>
          </div>
          <div>
            <el-input-number class="input-number txt-l" :min="0" :max="999999999" size="small"
                             :placeholder="$t('PleaseEnter')"
                             :controls="false"
                             :precision="2"
                             :class="{'is-error': $v.expectedPrice.$error}"
                             @blur="$v.expectedPrice.$touch()"
                             v-model.trim="expectedPrice"/>
          </div>
        </div>
        <!--出遊性質-->
        <div class="form-item">
          <div class="label" :class="{'is-error': $v.travelNature.$error}">{{$t('Nature of travel')}}</div>
          <div class="checkbox-group cl">
            <template v-for="item of travelNatureOptions">
              <template v-if="item.code!=='qita2'">
                <checkbox :key="item.code" v-model="item.isSelected">
                  <div class="checkbox-label">{{item.name}}</div>
                </checkbox>
              </template>
              <div style="padding-top:12px;clear:both;" :key="item.code" v-else>
                <checkbox style="margin:8px 8px 0 0" :key="item.code" v-model="item.isSelected">
                  <div class="checkbox-label">{{item.name}}</div>
                </checkbox>
                <input class="ipt" type="text" maxlength="100" :placeholder="$t('PleaseEnter')" autocomplete="off"
                       v-model.trim="otherNature"/>
              </div>
            </template>
          </div>
        </div>
        <!--報價截止日期-->
        <div class="form-item">
          <div class="label" :class="{'is-error': $v.deadlineDate.$error}">{{$t('Quote deadline')}}</div>
          <div>
            <el-date-picker class="select"
                            type="date"
                            size="small"
                            :picker-options="pickerOptions"
                            value-format="yyyy-MM-dd"
                            :placeholder="$t('PleaseChoose')"
                            :class="{'is-error': $v.deadlineDate.$error}"
                            @blur="$v.deadlineDate.$touch()"
                            v-model="deadlineDate">
            </el-date-picker>
          </div>
        </div>
        <!--附加說明或需求-->
        <div class="form-item m-r">
          <div class="label">{{$t('Additional instructions or requirements')}}({{$t('Optional')}})</div>
          <div>
            <el-input type="textarea"
                      :placeholder="$t('PleaseEnter')"
                      v-model="memo"
                      maxlength="1000">
            </el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="fl-group">
      <div class="hd">{{$t('ContactInformation')}}</div>
      <div class="bd">
        <!--称谓、聯絡人姓名-->
        <div class="form-item">
          <div class="label" :class="{'is-error': $v.isCalled.$error || $v.userName.$error}">
            {{$t('Contact Name')}}
          </div>
          <div class="cl">
            <el-select class="select w1 m-r" size="small" :placeholder="$t('Appellation')"
                       :class="{'is-error': $v.isCalled.$error}"
                       @blur="$v.isCalled.$touch()"
                       v-model="isCalled">
              <el-option v-for="item in genderOptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.code">
              </el-option>
            </el-select>
            <input class="ipt" type="text" autocomplete="off" :placeholder="$t('Name')"
                   :class="{'is-error': $v.userName.$error}"
                   @blur="$v.userName.$touch()"
                   v-model.trim="userName"/>
          </div>
        </div>
        <!--職位-->
        <div class="form-item" v-if="activeName==='group'">
          <div class="label" :class="{'is-error': $v.position.$error}">{{$t('Position')}}</div>
          <div>
            <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
                   :class="{'is-error': $v.position.$error}"
                   @blur="$v.position.$touch()"
                   v-model.trim="position"/>
          </div>
        </div>
        <!--聯絡電話-->
        <div class="form-item">
          <div class="label" :class="{'is-error': $v.phonePrefix.$error || $v.userPhone.$error}">
            {{$t('Contact Number')}}
          </div>
          <div class="cl">
            <el-select class="select w1" size="small" :placeholder="$t('Area code')"
                       :class="{'is-error': $v.phonePrefix.$error}"
                       @blur="$v.phonePrefix.$touch()"
                       v-model="phonePrefix">
              <el-option v-for="item in areaCodeOptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.code">
              </el-option>
            </el-select>
            <input class="ipt" type="number" autocomplete="off" :placeholder="$t('Contact Number')"
                   :class="{'is-error': $v.userPhone.$error}"
                   @blur="$v.userPhone.$touch()"
                   v-model.trim="userPhone"/>
          </div>
        </div>
        <!--E-mail-->
        <div class="form-item">
          <div class="label" :class="{'is-error': $v.userEmail.$error}">{{$t('Email')}}</div>
          <div>
            <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
                   :class="{'is-error': $v.userEmail.$error}"
                   @blur="$v.userEmail.$touch()"
                   v-model.trim="userEmail"/>
          </div>
        </div>
        <!--其他聯絡方式-->
        <div class="form-item">
          <div class="label">{{$t('Other Contact')}}({{$t('Optional')}})</div>
          <div>
            <div class="cl m-t" v-for="(item,index) of otherContact" :key="index">
              <el-select class="select w1" size="small" :placeholder="$t('PleaseChoose')" v-model="item.phoneType">
                <el-option v-for="item in phoneTypeOptions"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
              <el-select class="select w1 m-r" size="small" :placeholder="$t('Area code')" v-model="item.phonePrefix"
                         v-if="showPhoneType(item.phoneType)">
                <el-option v-for="item in areaCodeOptions"
                           :key="item.id"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
              <input class="ipt m-r" :type="showPhoneType(item.phoneType)?'number':'text'" autocomplete="off"
                     :placeholder="$t('PleaseEnter')"
                     v-model="item.userPhone"/>
              <!--删除聯絡方式-->
              <a class="btn-del" @click="delContact(index)">
                <img src="../../../assets/images/icon/ashcan-red.png"/>
              </a>
            </div>
            <div>
              <!--添加聯絡方式-->
              <a class="btn-add" @click="addContact">
                <img class="vam" src="../../../assets/images/icon/plus-circle-red.png"/>
                <span class="vam"> {{$t('Add contact')}}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="actions center-box">
      <button class="button button-primary m-r" type="button" @click="onSubmit('issue')">{{$t('Send quote')}}</button>
      <button class="button m-r" type="button" @click="onSubmit(isStatus||'edit')" v-if="!quoteId">
        {{$t('Save')}}
      </button>
      <button class="button" type="button" @click="closeWindow">{{$t('Cancel')}}</button>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import Vuelidate from 'vuelidate';
  import {required, between, requiredIf, email, minLength} from 'vuelidate/lib/validators';
  import Tabs from '../../../components/Tabs/Tabs';
  import TabPane from '../../../components/Tabs/TabPane';
  import Checkbox from '../../../components/Checkbox';
  import {getDictList} from '../../../api/common';
  import {saveQuote, getQuoteDetails} from '../../../api/quote';
  import {formatDate, scrollToY} from '../../../utils';

  Vue.use(Vuelidate);
  export default {
    name: 'UserQuoteEdit',
    data() {
      return {
        activeName: 'personal',
        quoteId: '',
        companyName: '',
        title: '',
        departure: '',
        destination: '',
        departureDate: '',
        traveledDay: undefined,
        adultJoinPeople: 0,
        childJoinPeople: 0,
        billingType: '',
        budgetType: '',
        expectedPrice: undefined,
        otherNature: '', // 其它出遊性質
        deadlineDate: '',
        memo: '',
        isCalled: '',
        userName: '',
        position: '',
        phonePrefix: '',
        userPhone: '',
        userEmail: '',
        otherContact: [],
        isStatus: '',
        // 以下是其它数据
        genderOptions: [],
        areaCodeOptions: [],
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now();
          }
        },
        currencyOptions: [],
        budgetTypeOptions: [
          {code: 'perCapita', name: this.$t('Per capita')},
          {code: 'lumpSum', name: this.$t('Total')}
        ],
        travelNatureOptions: [],
        phoneTypeOptions: [],
        travelNatureSelected: []
      };
    },
    computed: {
      travelNature() {
        return this.travelNatureOptions.filter(item => item.isSelected);
      }
    },
    validations() {
      return {
        companyName: {
          required: requiredIf((datas) => {
            return datas.activeName === 'group';
          })
        },
        departure: {required},
        destination: {required},
        departureDate: {required},
        traveledDay: {required, between: between(1, 365)},
        adultJoinPeople: {required, between: between(this.childJoinPeople > 0 ? 0 : 1, 99999)},
        childJoinPeople: {required, between: between(this.adultJoinPeople > 0 ? 0 : 1, 99999)},
        billingType: {required},
        budgetType: {required},
        expectedPrice: {required, between: between(0, 999999999)},
        deadlineDate: {required},
        isCalled: {required},
        userName: {required},
        position: {
          required: requiredIf((datas) => {
            return datas.activeName === 'group';
          })
        },
        phonePrefix: {required},
        userPhone: {required},
        userEmail: {required, email},
        travelNature: {required, minLength: minLength(1)}
      };
    },
    components: {Checkbox, Tabs, TabPane},
    created() {
      this.quoteId = this.$route.query.id || '';
      this.getDetail();
    },
    methods: {
      // 获取数据字典
      getDicts(travelNatureSelected = []) {
        getDictList('phonePrefix').then(res => {
          this.areaCodeOptions = res.value;
        });
        // 获取称谓选项
        getDictList('appellative').then(res => {
          this.genderOptions = res.value || [];
        });
        // 获取货币选项
        getDictList('billingType').then(res => {
          this.currencyOptions = res.value || [];
        });
        // 获取联系方式类型
        getDictList('phoneType').then(res => {
          this.phoneTypeOptions = res.value || [];
        });
        // 获取出游性质
        getDictList('travelNature').then(res => {
          this.travelNatureOptions = (res.value || []).map(item => {
            item.isSelected = travelNatureSelected.includes(item.code);
            return item;
          });
        });
      },
      // 添加聯絡方式
      addContact() {
        this.otherContact.push({phoneType: '', phonePrefix: '', userPhone: ''});
      },
      // 删除联络方式
      delContact(index) {
        this.otherContact.splice(index, 1);
        this.$nextTick(() => {
          if (!this.otherContact.length) {
            this.addContact();
          }
        });
      },
      // 获取详情
      getDetail() {
        if (this.quoteId) {
          getQuoteDetails(this.quoteId).then(res => {
            const datas = res.value || {};
            const quote = datas.quote || {};
            const contacts = datas.contacts || [];
            this.activeName = quote.quoteType;
            this.companyName = quote.companyName;
            this.title = quote.title;
            this.departure = quote.departure;
            this.destination = quote.destination;
            this.departureDate = quote.departureDate;
            this.traveledDay = quote.traveledDay;
            this.adultJoinPeople = quote.adultJoinPeople;
            this.childJoinPeople = quote.childJoinPeople;
            this.billingType = quote.billingType;
            this.budgetType = quote.budgetType;
            this.expectedPrice = quote.expectedPrice;
            this.otherNature = quote.otherNature;
            this.deadlineDate = formatDate(quote.deadlineDate, 'yyyy-MM-dd');
            this.memo = quote.memo;
            this.isStatus = quote.isStatus;
            this.getDicts(quote.travelNature.split(';'));

            // 联系人资料
            for (let item of contacts) {
              if (item.userName) {
                this.isCalled = item.isCalled;
                this.userName = item.userName;
                this.position = item.position;
                this.userEmail = item.userEmail;
                this.phonePrefix = item.phonePrefix;
                this.userPhone = item.userPhone;
              } else {
                this.otherContact.push({
                  phoneType: item.phoneType,
                  phonePrefix: item.phonePrefix,
                  userPhone: item.userPhone
                });
              }
            }
            if (!this.otherContact.length) {
              this.addContact();
            }
          });
        } else {
          this.addContact();
          this.getDicts();
        }
      },
      // 联系人资料
      getContacts() {
        let userContacts = [];
        const data = {
          contactType: 'Y',
          isCalled: this.isCalled,
          userName: this.userName,
          position: this.position,
          userEmail: this.userEmail,
          phoneType: '1',
          phonePrefix: this.phonePrefix,
          userPhone: this.userPhone
        };
        userContacts.push(data);
        for (let item of this.otherContact) {
          if (item.phoneType && item.userPhone) {
            userContacts.push({
              contactType: 'N',
              userName: '',
              isCalled: '',
              position: '',
              phoneType: item.phoneType,
              phonePrefix: this.showPhoneType(item.phoneType) ? item.phonePrefix : '',
              userPhone: item.userPhone,
              userEmail: ''
            });
          }
        }
        return userContacts;
      },
      // 发出报价/存储
      onSubmit(status) {
        const data = {
          quoteId: this.quoteId,
          quoteType: this.activeName,
          companyName: this.companyName,
          title: this.title,
          departure: this.departure,
          destination: this.destination,
          departureDate: this.departureDate,
          traveledDay: this.traveledDay,
          adultJoinPeople: this.adultJoinPeople,
          childJoinPeople: this.childJoinPeople,
          billingType: this.billingType,
          budgetType: this.budgetType,
          expectedPrice: this.expectedPrice,
          travelNature: this.travelNature.map(item => item.code).join(';'),
          travelNatureName: this.travelNature.map(item => item.name).join(';'),
          otherNature: this.otherNature,
          deadlineDate: this.deadlineDate,
          memo: this.memo,
          isStatus: status
        };
        const contacts = this.getContacts();

        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$message.warning(this.$t('Please complete the information before submitting'));
          this.$nextTick(() => {
            if (document.querySelector('.is-error')) {
              scrollToY(undefined, document.querySelector('.is-error').offsetTop - 80);
            }
          });
          return false;
        }

        saveQuote({quote: data, contacts}).then(res => {
          this.$message.success(status === 'issue' ? '提交成功' : this.$t('SaveSuccessfully'));
          this.$router.go(-1);
        }).catch(() => {
        });
      },
      showPhoneType(phoneType) {
        return ['shoutidianhua', 'Phone', 'Fax'].includes(phoneType);
      },
      closeWindow() {
        if (this.quoteId) {
          window.close();
        } else {
          this.$router.go(-1);
        }
      }
    }
  };
</script>
<style scoped lang="less">
  .main{
    .actions{margin-top:40px;}
    .checkbox-group{
      margin-top:-8px;
      /deep/ .checkbox-wrap{float:left;margin-right:30px;margin-top:12px;}
    }
    /deep/ .el-textarea .el-textarea__inner{height:96px;padding:10px;}
  }
  .fl-group{
    .hd{
      position:relative;margin:30px 0 20px;padding-left:16px;font-size:24px;
      &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
    }
    .bd{padding:20px;background-color:#f4f4f4;border-radius:4px;}
    .m-t{margin-top:8px;}
    .m-r{margin-right:8px;}
  }
  .form-item{
    margin-bottom:24px;
    &:last-child{margin-bottom:0;}
    .label{
      margin-bottom:8px;
      .tips{margin-top:4px;color:#999;}
    }
    .ipt{
      width:220px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
      &.w1{width:80px;margin-right:8px;}
    }
    .select{
      width:220px;vertical-align:top;
      &.w1{width:100px;margin-right:8px;}
    }
    .input-number{
      width:220px;vertical-align:middle;
      &.w1{width:130px;}
      &.txt-l{
        /deep/ .el-input__inner{text-align:left;}
      }
      /deep/ .el-input__inner{padding-left:10px;padding-right:10px;}
    }
    .btn-add{display:inline-block;margin-top:8px;color:#ff6f61;cursor:pointer;}
    .btn-del{display:inline-block;padding:6px 0;font-size:0;}
  }

  .center-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media only screen and (max-width: 768px) {
  button {
    margin-top: 10px;
  }
}
</style>
